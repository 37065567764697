import styled from "styled-components";
import { media } from "../../utils/styled/responsive";
import ScrollIcon from "../../components/Animations/ScrollIcon/ScrollIcon";
import LineReveal from "../../components/Text/LineReveal/LineReveal";
import { Theme } from "../../styles/theme";

export const LandingSection = styled.div`
  position: relative;
  width: 100vw;
  height: 90vh;
  display: grid;
  place-items: center;

  ${media.desktop} {
    height: 100vh;
  }
`;

export const StoreBTN = styled.a`
  position: absolute;
  border: 1px solid white;
  display: grid;
  place-items: center;
  z-index: 1;
  padding: 16px 5vw;
  bottom: 5vh;
  text-align: center;
  background: black;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  ${media.tablet} {
    bottom: 5vh;
  }

  ${media.desktop} {
    bottom: 25vh;
  }

  & :hover {
    transform: scale(1.1);
  }
`;

export const Heading = styled.div`
  transform: translateY(-10vh);
  height: min-content;
  text-align: center;

  ${media.tablet} {
    padding: 64px;
  }

  ${media.desktop} {
    transform: translateY(-10vh);
  }
`;

export const ScrollIndicator = styled(ScrollIcon)`
  display: none;

  ${media.desktop} {
    display: grid;
    position: absolute;
    right: 80px;
    bottom: 0;
    height: 20vh;
  }
`;

export const Award = styled.svg`
  height: 80px;
  padding-top: 24px;
`;
