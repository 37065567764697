import React, { FC } from "react";
import { useSpring, useTransform, useViewportScroll } from "framer-motion";

import useElementSize from "../../../hooks/useElementSize";

import * as S from "./FramerScroll.styles";

export interface FramerScrollProps {
  children: React.ReactNode;
}

const FramerScroll: FC<FramerScrollProps> = ({ children }) => {
  const { ref: ContentRef, size: ContentSize } = useElementSize();

  const { scrollYProgress } = useViewportScroll();
  const contentScroll = useTransform(
    scrollYProgress,
    [0, 1],
    [0, -ContentSize.scrollWidth + ContentSize.width]
  );

  const physics = { damping: 15, mass: 0.01, stiffness: 55 };
  const contentScrollSpring = useSpring(contentScroll, physics);

  return (
    <>
      <S.ScrollWrapper ref={ContentRef}>
        <S.ScrollContent style={{ x: contentScrollSpring }}>
          {children}
        </S.ScrollContent>
      </S.ScrollWrapper>
      <S.Ghost height={ContentSize.scrollWidth} />
    </>
  );
};
export default FramerScroll;
