import styled from "styled-components";

export const MobileGrid = styled.div`
  overflow: hidden;
  display: grid;
  gap: 160px;
`;

export const Container = styled.div<any>`
  position: fixed;
  width: 100vh;
  height: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  transform: rotate(-90deg) translateY(-100vh);
  transform-origin: right top;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Scroll = styled.div`
  position: relative;
  height: 100vh;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  transform: rotate(90deg);
  z-index: 0;
`;
