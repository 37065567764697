import React, { useEffect } from "react";
import useViewportSize from "../../hooks/useViewportSize";
import { Desktop, Mobile } from "../MediaQueries/MediaQueries";
import FramerScroll from "./Fallback/FramerScroll";
import { Container, MobileGrid, Scroll } from "./SideScroll.styles";
import { useScrollRestoration } from "gatsby";

function SideScroll({ children }) {
  const isBrowser = typeof window !== "undefined";
  const { size } = useViewportSize();

  const scrollRestoration = useScrollRestoration(`side-scroll`);

  useEffect(() => {
    if (size.width >= 992 && navigator.userAgent.indexOf("Firefox") === -1) {
      document.body.style.overflow = "hidden";
      document.body.addEventListener("touchmove", (e) => e.preventDefault(), {
        passive: false,
      });
    }

    return () => {
      document.body.removeEventListener("touchmove", (e) => e.preventDefault());
      document.body.style.overflow = "unset";
    };
  }, [size.width]);

  return (
    <>
      <Desktop>
        {isBrowser && navigator.userAgent.indexOf("Firefox") !== -1 ? (
          <FramerScroll>{children}</FramerScroll>
        ) : (
          <Container {...scrollRestoration}>
            <Scroll>{children}</Scroll>
          </Container>
        )}
      </Desktop>
      <Mobile>
        <MobileGrid>{children}</MobileGrid>
      </Mobile>
    </>
  );
}

export default SideScroll;
