import styled from "styled-components";
import { m } from "framer-motion";

import Tilt from "react-parallax-tilt";
import { media } from "../../utils/styled/responsive";

interface ColumnProps {
  order?: number;
}
interface HeaderProps {
  order?: number;
}

export const ProductItemWrapper = styled(m.div)`
  position: relative;
  padding: 0 24px;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);

  ${media.tablet} {
    padding: 0 64px;
    gap: 64px;
  }

  ${media.desktop} {
    grid-template-columns: 320px 320px;
    padding: 0;
    gap: 40px;
    width: auto;
  }
`;

export const Column = styled.div<ColumnProps>`
  position: relative;
  display: grid;
  place-items: center;
  max-width: 100%;
  grid-row: 1;
  grid-column: ${({ order }) => (order === 1 ? "2" : "unset")};

  ${media.desktop} {
    grid-column: unset;
  }
`;

export const TiltWrapper = styled(Tilt)`
  position: absolute;
  bottom: 10%;
  background: ${({ theme }) => theme.black[1]};
  width: 100%;
  padding-top: 100%;
`;

export const BottleWrapper = styled(m.div)`
  min-height: 100%;
  width: 50%;
  z-index: 1;
  pointer-events: none;

  transform: translateZ(1000px);

  & * {
    object-fit: cover;
    min-width: 100%;
  }
`;

export const Year = styled.div`
  position: absolute;
  right: -24px;
  top: -24px;

  ${media.desktop} {
    right: -40px;
    top: -24px;
  }
`;
export const Inner = styled.div`
  position: absolute;
  display: grid;
  width: 100%;
  padding-top: 100%;
  bottom: 10%;
  max-width: 100%;

  ${media.tablet} {
    width: calc(100% / 1.5);
    padding-top: calc(100% / 1.5);
  }

  ${media.desktop} {
    width: 320px;
    padding-top: 320px;
  }
`;

export const Header = styled.div<HeaderProps>`
  position: absolute;
  align-self: center;
  max-width: 100%;
  pointer-events: none;
  transform: translateZ(1000px) translateY(-100%);
  transform: ${({ order }) =>
    order !== 1 && "translateX(-32px) translateZ(1000px) translateY(-50%)"};

  ${media.tablet} {
    transform: translateZ(1000px);
  }

  ${media.desktop} {
    align-self: start;
    transform: translateX(-80px) translateY(80px) translateZ(1000px);
  }
`;

export const HeaderWrapper = styled.div`
  max-width: 40vw;
  width: fit-content;
  position: relative;
  pointer-events: all;
  cursor: pointer;

  & .line {
    white-space: unset;
  }

  & span {
    white-space: normal;
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;
