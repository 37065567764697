import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

import { useLanguage } from "../../hooks/useLanguage";
import Button from "../Button/Button";
import LineReveal from "../Text/LineReveal/LineReveal";

import * as S from "./ProductItem.styles";

function ProductItem({
  image,
  wineName,
  shortDescription,
  url,
  year,
  order,
  price,
}) {
  const [language] = useLanguage();

  const wineUnavailable = language === "en" ? "Explore" : "Poznaj wino";

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <S.ProductItemWrapper
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{ duration: 0.4 }}
    >
      <S.Column as={Link} to={url} order={order}>
        <S.BottleWrapper>
          <GatsbyImage image={image} alt={wineName} objectFit="fill" />
        </S.BottleWrapper>

        <S.TiltWrapper
          glareEnable={true}
          glareMaxOpacity={0.2}
          glareColor="white"
          glarePosition="all"
        />
      </S.Column>

      <S.Column>
        <S.Inner>
          <S.Header order={order}>
            <S.HeaderWrapper>
              <Link to={url}>
                <LineReveal renderAs="h3" text={wineName} />
                <S.Year>
                  <LineReveal
                    delay={0.2}
                    renderAs="small"
                    cName="year"
                    text="20"
                  />
                  <LineReveal
                    delay={0.4}
                    renderAs="small"
                    cName="year"
                    text={year}
                  />
                </S.Year>
              </Link>
            </S.HeaderWrapper>
            <LineReveal delay={0.6} renderAs="small" text={shortDescription} />
          </S.Header>
          <S.ButtonWrapper>
            <Button
              url={url}
              text={price ? price.toFixed(2) + "PLN" : wineUnavailable}
              goldLine
            />
          </S.ButtonWrapper>
        </S.Inner>
      </S.Column>
    </S.ProductItemWrapper>
  );
}

export default React.memo(ProductItem);
