import styled from "styled-components";
import { media } from "../../utils/styled/responsive";

export const List = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 80px;

  ${media.tablet} {
    grid-auto-flow: row;
    gap: 120px;
  }

  ${media.desktop} {
    grid-auto-flow: column;
    transform: translateY(5vh);
    gap: 160px;
  }
`;

export const WineContainer = styled.div`
  width: 100vw;
  height: 80vh;
  display: grid;
  place-items: center;
  max-width: 1600px;
`;
