import { getImage } from "gatsby-plugin-image";
import React from "react";
import { useLanguage } from "../../hooks/useLanguage";
import ProductItem from "../ProductItem/ProductItem";
import LineReveal from "../Text/LineReveal/LineReveal";
import { List, WineContainer } from "./ProductList.styles";
import { Link } from "gatsby";

interface ProductList {
  passedData: any;
  extraSection?: boolean;
  pageUrl?: "biale" | "czerwone" | "kolekcjonerskie" | "rozowe";
}

function ProductList({
  passedData,
  extraSection = false,
  pageUrl,
}: ProductList) {
  const [language] = useLanguage();

  console.log(pageUrl);
  return (
    <List>
      {passedData.allContentfulProduct.edges.map((element, index) => {
        const {
          image,
          id,
          shortDescription,
          url,
          year,
          wineName,
          priceNumber,
          category,
        } = element.node;

        const correctUrl = () => {
          if (url.charAt(0) === "/") {
            return url;
          } else {
            return `/${url}`;
          }
        };

        return (
          <>
            {pageUrl === "rozowe" && category === "rozowe" && (
              <ProductItem
                key={id + Math.random().toString()}
                order={index % 2 === 0 ? 1 : 0}
                url={correctUrl()}
                shortDescription={shortDescription}
                year={year}
                wineName={wineName}
                image={getImage(image)}
                price={priceNumber}
              />
            )}
            {priceNumber && pageUrl !== "kolekcjonerskie" && (
              <>
                {pageUrl === "biale" && category === "biale" && (
                  <ProductItem
                    key={id + Math.random().toString()}
                    order={index % 2 === 0 ? 1 : 0}
                    url={correctUrl()}
                    shortDescription={shortDescription}
                    year={year}
                    wineName={wineName}
                    image={getImage(image)}
                    price={priceNumber}
                  />
                )}
                {pageUrl === "czerwone" && category === "czerwone" && (
                  <ProductItem
                    key={id + Math.random().toString()}
                    order={index % 2 === 0 ? 1 : 0}
                    url={correctUrl()}
                    shortDescription={shortDescription}
                    year={year}
                    wineName={wineName}
                    image={getImage(image)}
                    price={priceNumber}
                  />
                )}

                {pageUrl === undefined && (
                  <ProductItem
                    key={id + Math.random().toString()}
                    order={index % 2 === 0 ? 1 : 0}
                    url={correctUrl()}
                    shortDescription={shortDescription}
                    year={year}
                    wineName={wineName}
                    image={getImage(image)}
                    price={priceNumber}
                  />
                )}
              </>
            )}
          </>
        );
      })}

      {extraSection && pageUrl === "kolekcjonerskie" && (
        <WineContainer>
          <LineReveal
            renderAs="h1"
            cName="xl"
            text={
              language === "pl" ? "Wina kolekcjonerskie" : "Collectible wines"
            }
          />
        </WineContainer>
      )}

      {passedData.allContentfulProduct.edges.map((element, index) => {
        const {
          image,
          id,
          shortDescription,
          url,
          year,
          wineName,
          priceNumber,
        } = element.node;

        const correctUrl = () => {
          if (url.charAt(0) === "/") {
            return url;
          } else {
            return `/${url}`;
          }
        };

        return (
          <>
            {!priceNumber && pageUrl === "kolekcjonerskie" && (
              <ProductItem
                key={id + Math.random().toString()}
                order={index % 2 === 0 ? 1 : 0}
                url={correctUrl()}
                shortDescription={shortDescription}
                year={year}
                wineName={wineName}
                image={getImage(image)}
                price={priceNumber}
              />
            )}
          </>
        );
      })}

      <WineContainer>
        <Link to={language === "pl" ? "/" : "/en"}>
          <LineReveal
            renderAs="h1"
            cName="xl"
            text={language === "pl" ? "Dom" : "Home"}
          />
        </Link>
      </WineContainer>
    </List>
  );
}

export default React.memo(ProductList);
