import styled from "styled-components";
import { m } from "framer-motion";

interface GhostProps {
  height: number;
}

export const ScrollWrapper = styled.div`
  position: fixed;
  height: 100vh;
  left: 0;
  right: 0;
`;
export const ScrollContent = styled(m.div)`
  display: flex;
  height: 100vh;
  width: max-content;
  align-items: center;
  will-change: transform;
`;

export const Ghost = styled.div<GhostProps>`
  height: ${({ height }) => (height ? `${height}px` : "100vh")};
`;
