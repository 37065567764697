import styled from "styled-components";
import { m } from "framer-motion";

export const Container = styled.div`
  display: grid;
  place-items: center;
  gap: 24px;
  grid-template-rows: auto 1fr;
  overflow: hidden;
`;

export const Text = styled.p`
  color: white;
  font-weight: 600;
  writing-mode: vertical-lr;
`;

export const Overflow = styled.div`
  height: 100%;
  overflow: hidden;
`;

export const AnimatedIndicator = styled(m.div)`
  transform-origin: bottom;
  width: 2px;
  height: 100%;
  background: white;
`;
