import React, { FC } from "react";

import { AnimatedIndicatorVariants } from "./ScrollIcon.animation";

import {
  Container,
  Overflow,
  AnimatedIndicator,
  Text,
} from "./ScrollIcon.styles";

export interface ScrollIconProps {}

const ScrollIcon: FC<ScrollIconProps> = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <Text>Scroll</Text>
      <Overflow>
        <AnimatedIndicator
          animate={"inOut"}
          variants={AnimatedIndicatorVariants}
        />
      </Overflow>
    </Container>
  );
};
export default ScrollIcon;
