export const AnimatedIndicatorVariants = {
  inOut: {
    y: ["-100%", "100%", "-100%", "100%"],
    transition: {
      duration: 6,
      repeat: Infinity,
      times: [0, 0.5, 0.5, 1],
      ease: "easeInOut",
    },
  },
};
